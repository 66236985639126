.PostActions svg {
  vertical-align: middle;
  margin-left: 5px;
  cursor: pointer;
  color: #76b9e5; }
  .PostActions svg.delete {
    color: #991e1e; }
  .PostActions svg.star {
    color: yellow; }
    .PostActions svg.star.voted {
      cursor: inherit; }
