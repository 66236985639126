::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent; }

::-webkit-scrollbar {
  width: 8px;
  background-color: transparent; }

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #bcdcf2; }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #76b9e5; }

input,
textarea,
select {
  border: 2px solid #bbc5cc;
  border-radius: 3px;
  font-family: "Merriweather", Helvetica, Arial, sans-serif; }
  input.invalid,
  textarea.invalid,
  select.invalid {
    border: 1px solid #991e1e; }

input,
select {
  padding: 4px 5px; }

button:disabled {
  color: #bcdcf2;
  border-color: #667a88;
  cursor: not-allowed; }

* {
  box-sizing: border-box; }

html, body, #root {
  height: 100%; }

body {
  font-family: "Merriweather", Helvetica, Arial, sans-serif;
  min-width: 290px;
  margin: 0;
  padding: 0; }

body,
input,
textarea,
select,
button {
  background-color: #0a1424;
  color: #e6f2fa;
  border-radius: 3px; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Palanquin", Helvetica, Arial, sans-serif;
  font-weight: 500;
  margin: 0.5em 0; }

button {
  border: 2px solid #bbc5cc;
  border-radius: 3px;
  font-family: "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold;
  padding: 3px 10px 5px 10px; }

a[href] {
  color: #76b9e5; }

a[href],
button {
  cursor: pointer; }

#root {
  position: fixed;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%; }
