.RequestAccess {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  overflow-y: auto;
  min-height: 300px;
  align-self: stretch; }
  .RequestAccess > p {
    margin: 2em 0;
    text-align: center; }
  .RequestAccess > p,
  .RequestAccess > input {
    width: 700px;
    max-width: 80%; }
  .RequestAccess > label {
    display: block;
    font-size: 0.8em; }
  .RequestAccess > input {
    margin: 0.5em; }
