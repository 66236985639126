.Alert {
  padding: 5px 5px 0; }
  .Alert .close {
    vertical-align: text-top;
    font-size: 1.5em;
    height: 0.75em;
    line-height: 0;
    overflow: hidden;
    margin: 0 5px;
    padding: 0 0 4px;
    border: none; }
    .Alert .close:hover {
      color: #00acff; }
