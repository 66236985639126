.Jump {
  z-index: 1;
  position: absolute;
  top: 5px;
  left: 50%;
  margin-left: -43px;
  padding: 0 5px 2px;
  border-radius: 3px;
  opacity: 0.65;
  background-color: #1e476d;
  cursor: pointer;
  text-align: center;
  font-family: "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif; }
  .Jump svg {
    font-size: 0.8em; }
  .Jump:hover {
    opacity: 0.9; }
