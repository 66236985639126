.PostContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 5px;
  flex: 1;
  min-height: 0;
  min-width: 0; }
  .PostContainer .post-wrapper {
    border: 2px solid #bbc5cc;
    border-radius: 3px;
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    flex: 1;
    overflow-y: auto;
    overflow-anchor: none;
    margin-bottom: 5px; }
    @supports (-moz-appearance: meterbar) {
      .PostContainer .post-wrapper {
        flex-direction: column;
        margin-top: auto; } }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .PostContainer .post-wrapper {
        flex-direction: column;
        margin-top: auto; } }
    @supports (-ms-ime-align: auto) {
      .PostContainer .post-wrapper {
        flex-direction: column;
        margin-top: auto; } }
  .PostContainer .input-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end; }
  .PostContainer .CloseButton {
    top: -0.15em;
    right: 0; }
