.UserActions {
  border: 2px solid #bbc5cc;
  border-radius: 3px;
  background-color: #152a4c; }
  .UserActions a {
    text-decoration: none; }
  .UserActions .action {
    cursor: pointer;
    padding: 2px;
    color: #e6f2fa; }
    .UserActions .action:hover {
      background-color: #2b579c; }
