.ImageDrop {
  position: relative;
  width: 250px;
  height: 250px;
  border-width: 2px;
  border-color: #bbc5cc;
  border-style: dashed;
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center; }
  .ImageDrop.active {
    border-style: solid;
    background-color: #1e476d; }
  .ImageDrop.reject {
    border-color: red; }
  .ImageDrop.accept {
    border-color: green; }
  .ImageDrop img {
    max-width: 100%;
    max-height: 100%; }
