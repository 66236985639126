.UserProfile .back {
  text-decoration: none; }
  .UserProfile .back svg {
    vertical-align: middle; }

.UserProfile .panels {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  align-items: flex-start; }
  .UserProfile .panels .Panel {
    margin-top: 10px;
    margin-right: 15px;
    min-width: 200px; }
    .UserProfile .panels .Panel h2 {
      text-align: center; }
    .UserProfile .panels .Panel select {
      width: 100%; }

.UserProfile ul {
  list-style-type: none;
  margin-top: 0;
  padding-left: 0; }
  .UserProfile ul li:not(:first-child) {
    margin-top: 10px; }
