.Profile form {
  margin-bottom: 1em; }

.Profile label {
  display: inline-block;
  width: 120px;
  font-size: 0.8em; }

.Profile label + input,
.Profile input + button {
  margin-left: 1em; }

.Profile input,
.Profile button {
  display: inline-block;
  margin: 3px 0; }
