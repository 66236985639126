.Post {
  padding: 8px 5px; }
  .Post:nth-child(even) {
    background-color: #152a4c; }
  .Post .timestamp {
    font-family: "Times New Roman", "Courier New", Courier, monospace;
    font-size: 0.8em; }
  .Post .poster {
    margin-right: 5px;
    font-weight: 800; }
    .Post .poster, .Post .poster > .User {
      display: inline-block; }
  .Post .chatmined {
    vertical-align: middle;
    margin-right: 5px; }
  .Post .post {
    font-family: "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
    vertical-align: top;
    margin-top: 2px;
    overflow: hidden;
    white-space: pre-wrap;
    overflow-wrap: break-word; }
    .Post .post p,
    .Post .post pre {
      margin: 0; }
    .Post .post p + p {
      margin-top: 0.4em; }
    .Post .post blockquote {
      margin: 0.5em 0 0.5em 1em;
      border-left: 3px solid #bbc5cc;
      padding-left: 1em; }
    .Post .post img {
      max-width: 100%;
      max-height: 100vh; }
