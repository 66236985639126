.UserList .users-table * {
  color: #e6f2fa;
  border-color: #bbc5cc;
  background-color: transparent; }

.UserList .users-table svg {
  fill: #76b9e5; }

.UserList .users-table .rdt_TableRow {
  cursor: pointer; }
  .UserList .users-table .rdt_TableRow:hover * {
    color: #76b9e5;
    font-weight: bold; }
