.SettingsContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  min-height: 0;
  position: relative; }
  .SettingsContainer .settings-pane {
    flex: 1;
    overflow-y: auto;
    padding: 5px 10px;
    background-color: #101f38; }
