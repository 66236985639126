.SidebarContainer {
  display: flex;
  flex-direction: column;
  max-width: 200px;
  height: 100%;
  padding: 0 2px; }
  .SidebarContainer .header {
    font-family: "Palanquin", Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-weight: bold;
    border-bottom: 2px solid #bbc5cc;
    padding: 0 4px;
    margin: 0 -2px; }
  .SidebarContainer .content {
    flex: 1;
    overflow-y: auto;
    padding: 2px; }
