.Header {
  display: flex;
  justify-content: space-between;
  flex: none;
  height: 45px;
  overflow: hidden;
  background-color: #1e476d;
  transition: height 500ms ease; }
  .Header .title-group {
    display: flex;
    flex: 1;
    overflow: hidden; }
    .Header .title-group a {
      display: flex;
      flex-shrink: 1;
      max-width: 100%; }
  .Header .logo {
    height: 45px; }
  .Header a,
  .Header .title {
    text-decoration: none; }
  .Header .title {
    flex-shrink: 1;
    margin: 0 10px;
    color: #e6f2fa;
    font-size: 1.2em;
    line-height: 42px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    .Header .title .short {
      display: none; }
    @media only screen and (max-width: 375px) {
      .Header .title .short {
        display: inline; }
      .Header .title .long {
        display: none; } }
  .Header .right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: none; }
    .Header .right .actions a {
      margin-left: 5px; }
    .Header .right .actions svg {
      font-size: 1.4em;
      vertical-align: middle; }
  @media screen and (max-height: 250px) {
    .Header {
      height: 0; } }
