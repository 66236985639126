.PostInput {
  position: relative;
  width: 100%; }
  .PostInput > .inner {
    font-family: "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
    border: 2px solid #bbc5cc;
    border-radius: 3px;
    padding: 4px;
    min-height: 33px;
    max-height: 150px;
    overflow: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-all;
    overflow-wrap: break-word;
    resize: none; }
  .PostInput > .LoadIndicator {
    position: absolute;
    right: 5px;
    top: 0; }
    .PostInput > .LoadIndicator .inner {
      width: 25px; }
