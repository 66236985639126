@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.LoadIndicator {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden; }
  .LoadIndicator > .inner {
    width: 70%;
    max-width: 500px;
    margin: auto;
    border: 5px solid #76b9e5;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-radius: 50%;
    animation: spin 1100ms infinite ease; }
    .LoadIndicator > .inner:after {
      content: "";
      display: block;
      padding-bottom: 100%; }
