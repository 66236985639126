.ExampleEmote {
  display: inline-block;
  width: 150px;
  margin: 10px;
  text-align: center; }
  .ExampleEmote img {
    height: auto;
    max-width: 100%; }
  .ExampleEmote .name {
    text-align: center;
    font-size: 0.8em;
    margin-top: 5px; }
