.ChatmineContainer {
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-self: stretch;
  min-height: 0;
  border: 2px solid #bbc5cc;
  border-radius: 3px;
  margin: 5px;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto; }
  .ChatmineContainer h2,
  .ChatmineContainer > p {
    padding: 2px;
    margin: 0; }
  .ChatmineContainer > p {
    margin-bottom: 1.5em; }
