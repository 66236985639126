.ChatSidebar {
  display: flex;
  flex-direction: column;
  border: 2px solid #bbc5cc;
  border-radius: 3px;
  position: relative;
  flex: initial;
  min-height: 0;
  min-width: 0;
  margin: 5px;
  margin-left: 0; }
  .ChatSidebar .collapse {
    z-index: 2;
    position: absolute;
    transform: translate(-100%, 20%);
    font-size: 1.2em;
    opacity: 0.4; }
    .ChatSidebar .collapse:hover {
      opacity: 0.8; }
